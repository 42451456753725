import React, { FC } from 'react';
import { navigate } from 'gatsby';
import { GenericLayout } from '../../layouts/generic';
import { Helmet } from 'react-helmet';
import { Title } from '@nn-virtual-pen/education/ui';
import { ActionButton, ActionButtonSize, ActionButtonVariant } from '@nn-virtual-pen/ui';
import { TranslationKey, useTranslate } from '@nn-virtual-pen/education/data-access';
import { RoutePath } from '@nn-virtual-pen/education/utils';
import { Home } from '@styled-icons/ionicons-solid/Home';
import * as Styled from './not-found.styled';

export const NotFound: FC = () => {
  const { t } = useTranslate();

  return (
    <GenericLayout>
      <Helmet title="404" />
      <Styled.Wrapper>
        <Title>{t(TranslationKey.page_not_found)}</Title>
        <ActionButton
          size={ActionButtonSize.wide}
          variant={ActionButtonVariant.primary}
          title={t(TranslationKey.return_home)}
          IconComponent={Home}
          onClick={() => void navigate(RoutePath.Home)}>
          {t(TranslationKey.return_home)}
        </ActionButton>
      </Styled.Wrapper>
    </GenericLayout>
  );
};

export default NotFound;
