import React, { FC } from 'react';
import { NotFound } from '../components/not-found';

export const NotFoundPage: FC = () => {
  return (
    <NotFound />
  );
};

export default NotFoundPage;
